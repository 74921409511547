import React from "react";
import "../styles/common-styles.css";
import { Link } from "react-router-dom";

export const About = () => {
  return (
    <div className="home font-link">
      <div className="hero">
        <div className="movieclubLogo">
          <div className="movie"></div>
          <div className="club"></div>
        </div>
        <Link to="/home" className="what-is back">&#128072; Back</Link>
      </div>
      <div className="list about">
        <div className="row">
          <div className="card">
            <div className="subtitle">Movie Club is Cool!</div>
            <div className="about-text">&nbsp; &nbsp; &nbsp; 
                If you’re new here, Movie Club is a pretty simple concept: come watch a surprise movie with me, Pooji Jonnavithula, anywhere in this silly little city. I tell you when we’re meeting, you pay me for your ticket, and the rest is a mystery! On the day of the movie, I’ll tell you exactly when and where to meet. Then we'll meet, we'll hang, we'll chit chat, and maybe even fall in love 💋 (falling in love is optional but encouraged).
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
};

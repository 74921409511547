import React from "react";
import "../styles/common-styles.css";
import { Link } from "react-router-dom";
import { SliderField, useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";

async function handleTix () {
  const stripe = await loadStripe('pk_test_51NrEQXAzzJMuXVAMlwlzAHwyQBCFQ8WJqrOGIgjnLhciUVZAAPWUuHfhvWOWb21tXP7WbUUaL24zYhgq0OoADSWh009OROfOhW');
  const { error } = await stripe.redirectToCheckout({
    lineItems: [{ price: "price_1NrFgQAzzJMuXVAMEeU7PaZn", quantity: 1 }],
    mode: "payment",
    successUrl: "https://www.movieclub.cool",
    cancelUrl: "https://www.movieclub.cool",
  }).then(function(response) {
    console.log(response.json())
    if(response.error){
      alert(response.error.message);
    }
  })
}

export const Home = () => {
  const navigate = useNavigate();
  const handleSignClick = () => navigate('../sign-in');

  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  return (
    <div className="home font-link">
      <div className="hero">
        <div className="movieclubLogo">
          <div className="movie"></div>
          <div className="club"></div>
        </div>
        <span className="links">
          <Link to="/about" className="what-is">What the heck is this?</Link>
          <>{authStatus === 'authenticated' ? 
          <Link onClick={signOut} className="sign-out">Sign Out</Link>
          : <Link to="/sign-in" className="sign-in">Sign in</Link>}</>
          
        </span>
      
      </div>
      <div className="list">
        <div className="row">
          <div className="card">
            <div className="image-container" />
            <div className="text-content">
              <p className="div">AN OOKY SPOOKY HALLOWEEN</p>
              <div className="text-content-2">
                <div className="subtitle">October Movie</div>
                <div className="subtitle-2">36 Going</div>
              </div>
              {/* <div className="frame">
              <form action="/buy-tickets" method="POST">
                {authStatus === 'authenticated' ? 
                  <>
                    <div className="text-wrapper-2" onClick={handleTix}>
                        <div className="text-wrapper-2">Get Your Tickets</div>
                    </div><div className="ellipse-left"></div><div className="ellipse-right"></div>
                  </> :
                  <><div className="text-wrapper-2" onClick={handleSignClick}>Sign In To Buy Tickets!</div><div className="ellipse-left"></div><div className="ellipse-right-2"></div></> 
                }
                
              </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row-wrapper">
        <div className="div-2">
          <div className="card-wrapper">
            <div className="card-2">
              <div className="image-container-2" />
              <div className="text-content-3">
                <div className="text-wrapper-3">THESE ARE THE DOG DAYS</div>
                <div className="div-2">
                  <div className="subtitle-3">September Movie</div>
                  <div className="subtitle-4">24 Went</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-wrapper">
            <div className="card-2">
              <div className="image-container-3" />
              <div className="text-content-3">
                <div className="text-wrapper-4">SHE'S BOTTOMING OUT BABES</div>
                <div className="div-2">
                  <div className="subtitle-3">August Movie</div>
                  <div className="subtitle-5">30 Went</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import "../styles/common-styles.css";
import { Link } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { Home } from "./home";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Navigate } from "react-router-dom";
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { API } from "aws-amplify";
// import { createTicket } from '../graphql/mutations';

// const newTicket = await API.graphql({
//     query: createTicket,
//     variables: {
//         input: {
//         "name": "Lorem ipsum dolor sit amet",
//         "phone_number": "Lorem ipsum dolor sit amet",
//         "email": "Lorem ipsum dolor sit amet",
//         "quantity": 1020
//     }
//     }
// });

export const Confirm = () => {
    
const navigate = useNavigate()
    useEffect(() => {
        setTimeout(() => {
        navigate('/home')
        }, 5000)
    }, [])
  return (
    <div className="home font-link">
      <div className="hero">
          <div className="movieclubLogo">
            <div className="movie"></div>
            <div className="club"></div>
          </div>
          <span className="links">
            <Link to="/home" className="what-is back">&#128072; Back</Link>
            <div className="sub-wh">You're Going To Movie Club!</div>
          </span>
          <span className="links">
            <div className="sub-wh">You'll be redirected in 5 seconds</div>
          </span>
        </div>
    </div>  
        
        );
    }
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { Home } from "./pages/home";
import { About } from "./pages/about";
import { Confirm } from "./pages/confirmation";
import { SignIn as SignPage}  from "./pages/signin";
import { useEffect } from "react";
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import awsExports from "./aws-exports";
import '@aws-amplify/ui-react/styles.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Authenticator } from "@aws-amplify/ui-react";

Auth.configure(awsExports);


function App({ signOut, user }) {
  
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      console.log("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      console.log(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
    

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/home" element={
        <Authenticator.Provider>
          <Home />
        </Authenticator.Provider>} />
      <Route path="/about" element={
        <Authenticator.Provider>
          <About />
        </Authenticator.Provider>} />
      <Route path="/sign-in" element={
        <Authenticator.Provider>
          <SignPage />
        </Authenticator.Provider>
        } />
      <Route path="/confirm" element={
        <Authenticator.Provider>
          <Confirm />
        </Authenticator.Provider>
        } />
      <Route path="/" element={
        <Authenticator.Provider>
          <Home />
        </Authenticator.Provider>} />
    </Routes>
  );
}
export default App;

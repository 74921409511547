import React from "react";
import "../styles/common-styles.css";
import { Link } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { Home } from "./home";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Navigate } from "react-router-dom";

export const SignIn = () => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  return (
    <><>
      {authStatus === 'configuring' && 'Loading...'}
      {authStatus !== 'authenticated' ? <div className="carpet home font-link">
        <div className="hero">
          <div className="movieclubLogo">
            <div className="movie"></div>
            <div className="club"></div>
          </div>
          <Link to="/home" className="what-is back">&#128072; Back</Link>
        </div>
        <Authenticator className="auth">
          {({ signOut, user }) => (
            <main>
              <h1>Hello {user.username}</h1>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator>
      </div> : <Navigate to="/home" replac={true}/>}
    </>
      </>
  );
};